var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"data-test-id":"driving-license-validation_view_validation-step1"}},[_c('h2',{staticClass:"my-3 emobg-font-large emobg-color-ink",domProps:{"innerHTML":_vm._s(_vm.$t('complete_documentation.manual_upload.check_driving_license.title'))}}),_c('p',{staticClass:"mb-6 font-regular emobg-font-medium emobg-color-ink",domProps:{"innerHTML":_vm._s(_vm.$t('complete_documentation.manual_upload.check_driving_license.note'))}}),_c('div',{class:[
      'mb-6',
      {'pb-5': !_vm.isDvlaRequiredByCsOperatorAndCountry }
    ]},[(_vm.isLoading)?_c('ui-loader'):_c('ui-select',{staticClass:"w-100",attrs:{"label":_vm.$t('common.country'),"placeholder":_vm.$t('common.country_placeholder'),"searchbox":{
        placeholder: _vm.$t('common.country_placeholder'),
        threshold: 5,
      },"value":_vm.country,"data-test-id":"driving_license_country-select","name":"countriesList"},domProps:{"options":_vm.mappedCountries},on:{"selectoption":({ detail }) => _vm.setCountrySelected(detail)}}),(_vm.isDvlaRequiredByCsOperatorAndCountry)?[_c('DvlaComponent',{staticClass:"w-50",attrs:{"value":_vm.dvla,"clean-number":_vm.cleanDvlaNumber},on:{"update:value":_vm.setDvla,"on:validate":_vm.setDvlaValid}}),_c('a',{staticClass:"RegisterLayout__link pl-0 ml-0",attrs:{"href":"https://www.viewdrivingrecord.service.gov.uk/driving-record/licence-number","target":"blank"}},[_vm._v(" "+_vm._s(_vm.$t('complete_documentation.manual_upload.check_driving_license.get_my_dvla_code'))+" ")])]:_vm._e()],2),_c('div',{staticClass:"d-flex"},[_c('ui-button',_vm._b({attrs:{"size":_vm.SIZES.large,"data-test-id":"driving-license-validation_button_later"},on:{"clickbutton":_vm.onClickDoItLater}},'ui-button',_vm.fetchButtonSpecs({ buttonType: _vm.THEME_BUTTON_TYPES.SECONDARY }),false),[_vm._v(" "+_vm._s(_vm.$t('complete_documentation.manual_upload.presentation.do_it_later'))+" ")]),_c('ui-button',_vm._b({staticClass:"w-30 ml-5",attrs:{"disabled":_vm.isButtonDisabled,"size":_vm.SIZES.large,"data-test-id":"driving-license-validation_button_next"},on:{"clickbutton":_vm.goStepUploadDrivingLicense}},'ui-button',_vm.fetchButtonSpecs(),false),[_vm._v(" "+_vm._s(_vm.$t('complete_documentation.manual_upload.check_driving_license.next_btn'))+" ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }