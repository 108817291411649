<template>
  <div data-test-id="driving-license-validation_view_validation-step2">
    <div class="d-flex mb-3">
      <ui-icon
        :icon="ICONS.arrowBack"
        :size="ICONS_SIZES.small"
        :color="GRAYSCALE.ink"
        class="cursor-pointer"
        data-test-id="arrow_back-icon"
        hover
        @click="() => goStepUploadDrivingLicense()"
      />
      <h2
        class="ml-3 emobg-font-weight-bold emobg-font-large emobg-color-ink"
        v-html="$t('complete_documentation.manual_upload.upload_driving_license.title')"
      />
    </div>

    <p
      class="mb-6 font-regular emobg-font-medium emobg-color-ink"
      v-html="$t('complete_documentation.manual_upload.upload_driving_license.subtitle')"
    />

    <div class="mb-6">
      <DrivingLicenseUploaderComponent
        :text="$t('complete_documentation.manual_upload.upload_driving_license.dl_front_2')"
        data-test-id="driving-license-validation_file-image_front-card"
        class="pb-4"
        @filechanged="setFrontDrivingLicense"
      />
      <hr>
      <DrivingLicenseUploaderComponent
        :text="$t('complete_documentation.manual_upload.upload_driving_license.dl_back_2')"
        data-test-id="driving-license-validation_file-image_back-card"
        class="py-4"
        @filechanged="setBackDrivingLicense"
      />
      <hr>
      <DrivingLicenseUploaderComponent
        :text="$t('complete_documentation.manual_upload.upload_driving_license.dl_selfie_2')"
        data-test-id="driving-license-validation_file-image_selfie"
        class="pt-4"
        @filechanged="setSelfieDrivingLicense"
      />
    </div>

    <div class="d-flex">
      <ui-button
        v-bind="fetchButtonSpecs()"
        :disabled="isButtonDisabled"
        :loading="status === STATUS.loading"
        data-test-id="driving_license_files_upload-button"
        class="mr-4"
        @clickbutton="onClickCreateDrivingLicense"
      >
        {{ $t('complete_documentation.manual_upload.upload_driving_license.upload_btn') }}
      </ui-button>
    </div>
  </div>
</template>

<script>

import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import { STORE_REQUEST_STATUS } from '@emobg/web-utils';

import { parseApiErrorMessage } from '@/utils/apiHelpers';

import { GTMModalEvents } from '@/constants/gtm';
import { useTrackingGTM } from '@/composable/GTM/gtm';
import { useTheme } from '@/composable/Theme/useTheme';
import DrivingLicenseUploaderComponent from '../DrivingLicenseUploaderComponent';
import * as DrivingLicenseRoadblockModule from '../DrivingLicenseRoadblockModule';

export default {
  name: 'DrivingLicenseValidationStepTwoComponent',

  components: {
    DrivingLicenseUploaderComponent,
  },

  inheritAttrs: false,

  props: {
    userUuid: {
      type: String,
      required: true,
    },
    userHasBadge: {
      type: Boolean,
      required: true,
    },
  },

  setup() {
    const { trackModalView } = useTrackingGTM();
    const { fetchButtonSpecs } = useTheme();

    return { fetchButtonSpecs, trackModalView };
  },

  computed: {
    ...mapState(DrivingLicenseRoadblockModule.NAMESPACE, {
      status: state => state.status,
    }),

    ...mapGetters(DrivingLicenseRoadblockModule.NAMESPACE, {
      isButtonDisabled:
          DrivingLicenseRoadblockModule.GETTERS.UPLOAD_DL_BUTTON_DISABLED,
    }),
  },

  created() {
    this.trackModalView({ modalName: GTMModalEvents.drivingLicenceValidationStep2 });
    this.STATUS = STORE_REQUEST_STATUS;
    // When parameter is null, IDLE status is set.
    // So, the button will be disabled every time the user
    // enters to this step
    this.setDLRoadblockErrorMessage(null);

    // Set images to null
    this.setFrontDrivingLicense();
    this.setBackDrivingLicense();
    this.setSelfieDrivingLicense();
  },

  methods: {
    ...mapActions(DrivingLicenseRoadblockModule.NAMESPACE, {
      goStepUploadDrivingLicense:
          DrivingLicenseRoadblockModule.ACTIONS.GO_STEP_SELECT_COUNTRY_DRIVING_LICENSE,
      goStepPendingDrivingLicenseValidation:
          DrivingLicenseRoadblockModule.ACTIONS.GO_STEP_PENDING_DL_VALIDATION,
      createDrivingLicense:
          DrivingLicenseRoadblockModule.ACTIONS.CREATE_DRIVING_LICENSE,
      setDLRoadblockErrorMessage:
          DrivingLicenseRoadblockModule.ACTIONS.SET_STATUS_AND_ERROR_MESSAGE,
    }),

    ...mapMutations(DrivingLicenseRoadblockModule.NAMESPACE, {
      setFrontDrivingLicense:
          DrivingLicenseRoadblockModule.MUTATIONS.SET_FRONT_DL,
      setBackDrivingLicense:
          DrivingLicenseRoadblockModule.MUTATIONS.SET_BACK_DL,
      setSelfieDrivingLicense:
          DrivingLicenseRoadblockModule.MUTATIONS.SET_SELFIE_DL,
      setDrivingLicenseCreated:
          DrivingLicenseRoadblockModule.MUTATIONS.SET_DRIVING_LICENSE_CREATED,
      setDrivingLicenseNotCreated:
          DrivingLicenseRoadblockModule.MUTATIONS.SET_DRIVING_LICENSE_NOT_CREATED,
    }),

    async onClickCreateDrivingLicense() {
      try {
        const response = await this.createDrivingLicense({
          data: {
            userUuid: this.userUuid,
          },
          errors: {
            notCompleted: this.$t('complete_documentation.manual_upload.upload_driving_license.steps_are_mandatory'),
          },
        });

        this.setDrivingLicenseCreated(response);

        this.$emit('on:driving-license-created');

        this.onClickNextStep();
      } catch (error) {
        const parsedError = parseApiErrorMessage(this.$t, this.$i18n, error);
        this.setDrivingLicenseNotCreated(parsedError);
        this.$emit('on:error', error);
      }
    },

    onClickNextStep() {
      this.goStepPendingDrivingLicenseValidation();
    },
  },
};

</script>
